<mat-toolbar color="accent" style='position:fixed; z-index:888'>
  <mat-toolbar-row fxShow fxHide.xs="true" fxLayout="row" fxLayoutAlign="start center">
    <span [routerLink]="['']" style="cursor: pointer; outline: none">
      <mat-icon fontSet="fa" fontIcon="fa-plus"></mat-icon>
      RedQuest <small class="mat-small projId" *ngIf="!env.production">{{env.firebaseConfig.projectId}}</small>
    </span>
    <ng-container *ngIf="connected">
      <span fxFlex="50px"></span>
      <button mat-button routerLinkActive="selected" [routerLink]="['tips']">Astuces de quête</button>
      <button mat-button routerLinkActive="selected" [matMenuTriggerFor]="menu">Ma quête<mat-icon>
          arrow_drop_down
        </mat-icon></button>

      <button mat-button routerLinkActive="selected" [routerLink]="['ranking']"
        *ngIf="ulPrefs!= null && ulPrefs.rq_display_queteur_ranking!='NON'">Qui est au top?</button>
      <button mat-button routerLinkActive="selected" [routerLink]="['local-unit']">Mon unité locale</button>
    </ng-container>
    <span fxFlex></span>
    <button *ngIf="authenticated && queteur" mat-button [matMenuTriggerFor]="menuUser" routerLinkActive="selected">
      {{queteur.first_name}}
      <mat-icon> arrow_drop_down </mat-icon>
    </button>
    <button mat-button *ngIf="!authenticated" routerLinkActive="selected" [routerLink]="['login']">
      Se connecter
    </button>
    <button  mat-button *ngIf="authenticated"  routerLinkActive="selected" (click)="logout()">
      <mat-icon>vpn_key</mat-icon><span>Se déconnecter</span>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row fxShow fxHide.gt-xs="true" fxLayout="row" fxLayoutAlign="space-between center">
    <span [routerLink]="['']">
      <mat-icon fontSet="fa" fontIcon="fa-plus"></mat-icon> RedQuest
    </span>
    <button mat-icon-button [matMenuTriggerFor]="menuFull" routerLinkActive="selected">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menuUser="matMenu">
  <button mat-menu-item [routerLink]="['credits']">
    <mat-icon>info_outline</mat-icon>
    <span>A propos</span>
  </button>
  <mat-divider></mat-divider>
  <ng-container *ngIf="authenticated">
    <button mat-menu-item [routerLink]="['account']">
      <mat-icon>face</mat-icon>
      <span>Mon compte</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>vpn_key</mat-icon><span>Se déconnecter</span>
    </button>
  </ng-container>
  <button mat-menu-item *ngIf="!authenticated" routerLinkActive="selected" (click)="login()">
    <mat-icon>vpn_key</mat-icon><span>Se connecter</span>
  </button>
</mat-menu>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let link of myLinks" [routerLink]="[link.route]">
    <mat-icon>{{link.icon}}</mat-icon>{{link.label}}
  </button>
</mat-menu>

<mat-menu #menuFull="matMenu">
  <ng-container *ngIf="connected">
    <button mat-menu-item *ngFor="let link of allLinks" [routerLink]="[link.route]">
      <mat-icon>{{link.icon}}</mat-icon>{{link.label}}
    </button>
  </ng-container>
  <button mat-menu-item *ngIf="!authenticated" [routerLink]="['login']">
    <mat-icon>vpn_key</mat-icon><span>Se connecter</span>
  </button>
  <button mat-menu-item *ngIf="authenticated" (click)="logout()">
    <mat-icon>vpn_key</mat-icon><span>Se déconnecter</span>
  </button>
</mat-menu>
<div fxLayout="row" fxFlexFill style='padding-top:64px'>
  <div fxFlex.gt-xs="80" fxFlex.xs="92" fxFlexOffset.gt-xs="10" fxFlexOffset.xs="4" fxLayout="column">
    <router-outlet></router-outlet>
  </div>
</div>

<section class="jumb">
  <h2>Astuces</h2>
</section>
<div class="resp-container">
  <iframe class="resp-iframe" src="https://www.youtube.com/embed/B_6UThpexoo" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<div style="margin: 1em;">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Ma visibilité
        </mat-panel-title>
        <mat-panel-description>
          Bien se placer
        </mat-panel-description>
      </mat-expansion-panel-header>
      <h2>Pour des raisons de sécurité et pour que vous soyez plus à l’aise, quêtez en binôme
        ou ayez, tout du moins, toujours en visu un autre bénévole.</h2>
      <h2>Privilégiez les points de passage :</h2>
      <h4> - À un feu rouge, un péage</h4>
      <p>En règle générale, les feux rouges sont d’excellents points de quête, n’hésitez pas à
        vous y rendre en priorité.</p>

      <h4> - À l’entrée d’un supermarché, d’une galerie marchande, de petits commerces
        (boulangerie, tabac), d’un salon/foire expo</h4>
      <h4> - À la sortie d’un parking, d’un lieu de culte, d’un arrêt fréquenté de transport en
        commun, d’un cinéma, d’un bar, d’une salle de spectacle, d’un stade</h4>
      <h4> - Sur le marché</h4>
      <h4> - À la terrasse d’un café</h4>
      <h4> - Devant voire à l’intérieur d’un bureau de poste (nous sommes partenaires)</h4>

      <h2>POINT DE VIGILANCE</h2>
      <h4>(1)</h4>
      <p>dès votre arrivée devant un lieu privé, vous devez toujours vous
        manifester auprès d’un responsable ou a minima d’un employé et demander une
        autorisation verbale pour quêter sur ce lieu.
        Si vous rencontrez un refus, vous pouvez toujours tenter la réponse suivante : « Les
        bénévoles de la Croix-Rouge française ne sont présents qu’une semaine dans l’année. Cette
        quête va nous permettre de développer nos actions locales. »
        Si le refus persiste, ne vous opposez pas et demandez un peu plus loin sur un autre lieu.
      </p>
      <h4>(2)</h4>
      <p>Il est interdit de quêter dans les transports en commun.</p>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Mon accroche
        </mat-panel-title>
        <mat-panel-description>
          Capter l'attention
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p> - Il est obligatoire de porter l’emblème de l’association. Vous serez ainsi
        immédiatement identifié.</p>
      <p> - Soyez souriant, avenant et dynamique !
      Ne restez pas statique mais toujours en mouvement.
      Evitez de garder vos mains dans vos poches.
        Evitez de consulter de manière prolongée votre téléphone.</p>
      <p> - Lorsque vous vous adressez à un passant, soyez proche de lui mais pas trop. Soyez
      dynamique mais pas agressif.
      L’enjeu est de donner envie à la personne de baisser la fenêtre de son véhicule, de faire un
      don. La posture à adopter est celle du « Aller vers ». Il vous faut être très avenant, très
        charismatique.</p>
      <h2>Une accroche simple à retenir : <br/>« Bonjour ! Un don pour la Croix-Rouge ? »</h2>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Mon discours
        </mat-panel-title>
      </mat-expansion-panel-header>
      Il est important de toujours saluer la personne et de la remercier en fin d’échange.
      <p>Ne rentrez jamais dans un débat politique. Vous portez l’emblème de la Croix-Rouge
        française. Par conséquent, vous devez respecter les principes et valeurs de
        l’association et notamment le principe de neutralité.</p>
      <h4>Pitch type: </h4>
      <p>« Les Journées nationales, c’est un rendez-vous annuel au cours duquel la Croix-Rouge
        française va à la rencontre de la population. Un rendez-vous installé depuis 1934 ! C’est
        l’occasion pour vous de soutenir nos actions au local : les actions sociales, les actions
        d’urgence, de secourisme et de formation. Voulez-vous soutenir nos actions ? »</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>

export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyBQz6gxLDuolFUfLmetDUHNMH5-0LMUyrk',
        authDomain: 'rq-fr-dev.firebaseapp.com',
        databaseURL: 'https://rq-fr-dev.firebaseio.com',
        projectId: 'rq-fr-dev',
        storageBucket: 'rq-fr-dev.appspot.com',
        messagingSenderId: '411868841454'
    },
    'cloudFunctionsBaseUrlComment':'For NON authenticated CloudFunction',
    cloudFunctionsBaseUrl: 'https://europe-west1-rq-fr-dev.cloudfunctions.net/',
    google_maps_key: 'AIzaSyAUKcfp4BW2S5Z6WMdhpOZ4JEj92pNdczM',
    ranking_enabled: true,
    history_enabled: true,
    badges_enabled: true
};

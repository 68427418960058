<div *ngIf="ulPrefs.rq_display_queteur_ranking=='NON'">
  cette fonctionnalité a été désactivé par l'administrateur de votre unité locale.
</div>
<div *ngIf="ulPrefs.rq_display_queteur_ranking!='NON'">
  <section class="jumb">
    <h2>Qui est au top ? </h2>
  </section>

  <mat-form-field>
    <mat-label>Année</mat-label>
    <mat-select [(ngModel)]="year" (selectionChange)="selectPage()">
      <mat-option *ngFor="let selectYear of years" [value]="selectYear"> {{selectYear}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button class="lone-button" color="warn" mat-raised-button (click)="whereAmI()">Où suis-je ?</button>


  <div class="table-container">

    <mat-table *ngIf="enabled" [dataSource]="dataSource" matSort matSortActive="amount" matSortDirection="desc"
               matSortDisableClear>

      <ng-container matColumnDef="last_name">
        <mat-header-cell *matHeaderCellDef>Queteur</mat-header-cell>
        <mat-cell *matCellDef="let ranking" data-label="Queteur">
          <span [ngStyle]="{'font-weight':ranking.queteur_id === queteur.queteur_id ? 'bold':'normal'}">{{ranking.first_name|titlecase}} {{ranking.last_name|titlecase}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="number_of_tronc_queteur">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de troncs</mat-header-cell>
        <mat-cell *matCellDef="let ranking" data-label="Nombre de troncs">
          <span  [ngStyle]="{'font-weight':ranking.queteur_id === queteur.queteur_id ? 'bold':'normal'}">{{ranking.number_of_tronc_queteur||0}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Montant total</mat-header-cell>
        <mat-cell *matCellDef="let ranking" data-label="Montant total">
          <span [ngStyle]="{'font-weight':ranking.queteur_id === queteur.queteur_id ? 'bold':'normal'}"
          >{{ranking.amount||0 |number:'1.0-2':'fr-FR' }}&euro;</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="weight">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Poids</mat-header-cell>
        <mat-cell *matCellDef="let ranking" data-label="Poids">
          <span  [ngStyle]="{'font-weight':ranking.queteur_id === queteur.queteur_id ? 'bold':'normal'}">{{ranking.weight||0 | weight}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="time_spent_in_minutes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Temps total</mat-header-cell>
        <mat-cell *matCellDef="let ranking" data-label="Temps total">
          <span  [ngStyle]="{'font-weight':ranking.queteur_id === queteur.queteur_id ? 'bold':'normal'}">{{ranking.time_spent_in_minutes||0 | time: {small:true} }}</span>
        </mat-cell>

      </ng-container>
      <ng-container matColumnDef="unique_point_quete_count">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Lieux de quête</mat-header-cell>
        <mat-cell *matCellDef="let ranking" data-label="Lieux de quête">
          <span  [ngStyle]="{'font-weight':ranking.queteur_id === queteur.queteur_id ? 'bold':'normal'}">{{ranking.number_of_point_quete}}/{{ranking.total_number_of_point_quete}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="year">
        <mat-header-cell *matHeaderCellDef>Année</mat-header-cell>
        <mat-cell *matCellDef="let ranking" data-label="Année">
          <span  [ngStyle]="{'font-weight':ranking.queteur_id === queteur.queteur_id ? 'bold':'normal'}">{{ranking.year}}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
    <mat-paginator *ngIf="enabled && ulPrefs.rq_display_queteur_ranking=='ALL'" [length]="dataSource.count()" [pageSize]="10" [pageSizeOptions]="[ 5, 10, 25]"></mat-paginator>
    <mat-progress-bar *ngIf="enabled && dataSource.loading$ | async" mode="query"></mat-progress-bar>
  </div>
</div>
